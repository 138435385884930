import React from "react"
import { graphql } from "gatsby"
import remark from 'remark'
import remarkHTML from 'remark-html'
import { Fade } from "react-awesome-reveal";
import HomeBanner from "../components/homeBanner"
import LogoStrip from "../components/logoStrip"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"

const IndexPage = ({ data }) => {
  const toHTML = value => remark().use(remarkHTML).processSync(value).toString();

  const title = data.pageContent.frontmatter.title;
  const bannerTitle = data.pageContent.frontmatter.banner.small_title;
  const bannerCaption = toHTML(data.pageContent.frontmatter.banner.caption);
  const bannerImage = data.pageContent.frontmatter.banner.banner_image.childImageSharp.gatsbyImageData;
  const content = data.pageContent.html;
  const sectionsContent = data.pageContent.frontmatter.sections;
  const logoContent = toHTML(data.pageContent.frontmatter.logo_content);
  const schools = data.schools.frontmatter.schools;

  // Loop over page sections for display
  const Sections = () => {
    // Create each section, convert markdown to html
    const Section = ({ title, caption, button_text, url, image }) => {
      return (
        <div className="tile">
          <div className="row no-gutters">
            <div className="col">
              <div className="content-wrapper">
                <Fade triggerOnce>
                  <h3>{title}</h3>
                  <p className="lead">{caption}</p>
                  <Link to={url} className="button">
                    {button_text} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><polygon fill="#fff" points="9.3,1.3 7.9,2.7 12.2,7 0,7 0,9 12.2,9 7.9,13.3 9.3,14.7 16,8 "></polygon></svg>
                  </Link>
                </Fade>
              </div>
            </div>
            <div className="col">
              <div className="tile-image">
                <GatsbyImage image={image} alt={title} />
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <section className="tiles">
        {sectionsContent.map((section, index) =>
          <Section title={section.title} caption={section.caption} button_text={section.button_text} url={section.url} image={section.image.childImageSharp.gatsbyImageData} key={index} />
        )}
      </section>
    );
  }

  return (
    <Layout>
      <Seo title={title} description={content} />
      <HomeBanner title={bannerTitle} caption={bannerCaption} image={bannerImage} />
      <section className="intro">
        <div className="container">
          <Fade triggerOnce>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Fade>
        </div>
      </section>
      <section className="container">
        <Sections />
      </section>
      <section className="centered-section">
        <div className="container">
          <Fade triggerOnce>
            <div dangerouslySetInnerHTML={{ __html: logoContent }} />
          </Fade>
        </div>
      </section>
      <LogoStrip schools={schools} />
    </Layout>
  )
}

export const query = graphql`{
  pageContent: markdownRemark(frontmatter: {template_key: {eq: "home"}}) {
    frontmatter {
      title
      banner {
        small_title
        caption
        banner_image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
      sections {
        title
        caption
        button_text
        url
        image {
          childImageSharp {
            gatsbyImageData(width: 900, quality: 100, layout: CONSTRAINED)
          }
        }
      }
      logo_content
    }
    html
  }
  schools: markdownRemark(frontmatter: {template_key: {eq: "schools"}}) {
    frontmatter {
      schools {
        image {
          childImageSharp {
            gatsbyImageData(
              width: 900
              quality: 100
              placeholder: TRACED_SVG
              layout: CONSTRAINED
            )
          }
        }
        url
      }
    }
  }
}
`

export default IndexPage
