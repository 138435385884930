import React from "react"
import { Link } from "gatsby"
import { Fade } from "react-awesome-reveal";
import { GatsbyImage } from "gatsby-plugin-image";

const HomeBanner = ({ title, caption, image }) => (
  <section className="banner home-banner">
    <GatsbyImage image={image} alt={title} />
    <div className="banner-overlay-horizontal" />
    <div className="banner-overlay-vertical" />
    <div className="banner-content">
      <div className="container">
        <div className="row">
          <div className="col">
            <Fade triggerOnce>
              <p>{title}</p>
              <div dangerouslySetInnerHTML={{ __html: caption }} />
              <Link to="/schools/" className="button">
                Find your school <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><polygon fill="#ffec81" points="9.3,1.3 7.9,2.7 12.2,7 0,7 0,9 12.2,9 7.9,13.3 9.3,14.7 16,8 "></polygon></svg>
              </Link>
            </Fade>
          </div>
          <div className="col">
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default HomeBanner
