import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { Fade } from "react-awesome-reveal";
import { Link } from "gatsby"

const LogoStrip = ({ schools }) => (
  <section className="logos">
    <div className="container">
      <div className="row">
        {schools.map((school, index) =>
          <div className="col col-one-eighth" key={index}>
            <Link to={school.url} className="logo">
              <Fade cascade triggerOnce>
                <GatsbyImage image={school.image.childImageSharp.gatsbyImageData} />
              </Fade>
            </Link>
          </div>
        )}
      </div>
    </div>
  </section>
)

export default LogoStrip
